.App {
  text-align: left;
  height: 100vh;
  width: 100vw;
  border: 1px solid darkgray
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: whitesmoke;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}




.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}



.custom-tooltip {
  border: 1px solid silver;
  background-color: whitesmoke;
  padding: 8px;
}

.custom-tooltip p {
  margin: 2px;
}

.body-active td {
  border: 1px solid black;
  font-weight: 600;
}

.react-datepicker-wrapper {
  padding: 0px 5px 15px 5px !important;
}


.home-container {
  width: 100%;
  height: 100%;
  /* border: 1px solid gray; */
}

.home-graph {
  height: calc(100% - 200px);
  width: calc(100% - 0px);
  overflow: auto;
  border-right: 1px solid gray;
  border-bottom: 1px solid gray;
}


.home-phases {
  height: 200px;
  width: 100%;

}

.side-bar {
  /* border: 1px solid gray; */
  height: 100%;
  padding: 5px 0px !important;
  width: 300px;
  float: left;
  overflow-y: auto;
  overflow-x: hidden;
}


.graph-container {
  border-left: 1px solid gray;
  height: 100%;
  width: calc(100% - 300px);
  margin: 0px !important;
  float: right;
  min-width: 750px;
}


.estreon-graph {
  border: none;
  margin: 0px !important;
  padding: 0px !important;

}

.graph-top-container {
  width: calc(100% - 0px);
  height: calc(100% - 60px);
  margin: 0px !important;
  padding: 0px !important;
}

.graph-zoom-container {
  width: 60px;
  height: 100%;
}

.graph-graph-container {
  height: 100%;
}

.date-slider-container {
  height: 60px;
}

.side-bar-menu {
  border-bottom: 1px solid gray
}

.side-bar-menu .p-fieldset .p-fieldset-legend {
  padding: 8px;
}

.side-bar-menu .p-fieldset .p-fieldset-content {
  padding: 5px;
}


.side-bar-menu .field {
  margin-bottom: 4px;
  font-size: 14px;
}

.side-bar-menu .field>label {
  display: inline-block;
  margin-bottom: 2px;
}

.side-bar-data {
  /* border: 1px solid rebeccapurple */
}

.side-bar-extra-info {
  border-bottom: 1px solid gray;
  font-size: 14px;
}


.extra-info-box {
  padding: 5px 10px;
}

.extra-info-box label {
  font-weight: 600;
  margin-right: 5px;
}

.extra-info-box .extra-info-data {
  font-weight: 400;

}

.horizontal-slider {
  text-align: center;
}

.horizontal-slider.date-slider {
  width: 100%;
  height: 40px;
  border: none;
  margin-left: 10px;
  margin-right: 10px;


}

.horizontal-slider.date-slider .track {
  top: 20px;
  height: 2px;
  background: gray;
}

.horizontal-slider.date-slider .track-1 {
  top: 20px;
  height: 2px;
  background: gray;
}

.horizontal-slider.date-slider .thumb {
  top: 5px;
  height: 30px;
  width: 180px;
  border-radius: 20px;
  line-height: 30px;
  border: 1px solid gray;
  background-color: silver;
  cursor: pointer;
  font-size: 14px;
  box-shadow: 1px 1px #888888;
}

.vertical-slider {
  text-align: center;
}

.vertical-slider.zoom-slider {
  width: 50px;
  height: calc(100% - 20px);
  border: none;
  margin-top: 10px;
  margin-bottom: 10px;

}

.vertical-slider.zoom-slider .track {
  left: 30px;
  width: 2px;
  background: gray;
}

.vertical-slider.zoom-slider .track-1 {
  left: 30px;
  width: 2px;
  background: gray;
}

.vertical-slider.zoom-slider .thumb {
  left: 5px;
  height: 30px;
  width: 50px;
  border-radius: 20px;
  line-height: 30px;
  border: 1px solid gray;
  background-color: silver;
  cursor: pointer;
  font-size: 12px;
  box-shadow: 1px 1px #888888;

}

.control-box {
  margin-bottom: 5px;
  min-width: 280px;
}


.horizontal-slider.control-box-slider {
  width: calc(100% - 5px);
  height: 40px;
  border: none;
  margin-left: 2px;
  margin-right: 2px;

}

.horizontal-slider.control-box-slider .track {
  top: 20px;
  height: 2px;
  background: gray;
}

.horizontal-slider.control-box-slider .track-1 {
  top: 20px;
  height: 2px;
  background: gray;
}

.horizontal-slider.control-box-slider .thumb {
  top: 5px;
  height: 30px;
  width: 30px;
  border-radius: 20px;
  line-height: 30px;
  border: 1px solid gray;
  background-color: silver;
  cursor: pointer;
  font-size: 14px;
  box-shadow: 1px 1px #888888;
}


.control-box-header {
  line-height: 16px;
  text-align: center;
}

.active-input-switch {
  height: 14px;
  line-height: 16px;
}

.side-bar-menu fieldset {
  margin-top: 6px;
  margin-left: 10px;
  margin-right: 5px;
}

.control-box-dates {
  font-size: 14px;
}



.control-box-dates .date-block {
  height: 30px;

}

.control-box-dates label {
  display: inline-block;
  min-width: 68px;
}

.control-box-calendar {
  width: 150px;
  font-size: 14px;
  margin-left: 5px;
}

.control-box-dates .p-inputtext {
  font-size: 14px;
  padding: 4px;
}

.planet-table {}

.tcalc-table td {
  font-size: 14px;
  padding: 2px;
}

.tcalc-table th {
  font-size: 14px;
  padding: 2px;
}

.planet-table td {
  font-size: 14px;
  padding: 2px;
}

.planet-table .p-datatable-tbody > tr.p-highlight {
  background: transparent !important;
}

.planet-table .p-checkbox {
  width: 21px;
  height: 21px;
}

.phases-table th {
  font-size: 14px;
  padding: 2px;
}

.phases-table td {
  font-size: 14px;
  padding: 2px;
}

.planet-table th {
  font-size: 14px;
  padding: 2px;
}

